<template>
  <div>
    <b-row>
      <b-col cols="4">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="search"
            placeholder="Tên khách hàng"
          />
        </b-input-group>
      </b-col>

      <b-col cols="2">
        <b-form-datepicker
          v-model="dateFrom"
          local="vn"
          placeholder="Từ ngày"
          selected-variant="primary"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        />
      </b-col>

      <b-col>
        <b-form-datepicker
          v-model="dateTo"
          local="vn"
          placeholder="Đến ngày"
          selected-variant="primary"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        />
      </b-col>

      <b-col cols="2">
        <b-form-group>
          <v-select
            v-model="userStatus"
            placeholder="Trạng thái"
            label="text"
            :options="userStatusOptions"
            :reduce="item => item.value"
            :clearable="false"
          />
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-button
          variant="primary"
          class="mr-1"
          @click="fetchMerchant"
        >
          Tìm kiếm
        </b-button>
      </b-col>
    </b-row>

    <b-table
      small
      :fields="fields"
      :items="items"
      responsive="sm"
      class="mt-2"
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(status)="data">
        <span>{{ mapStatus(data.value) }}</span>
      </template>

      <template #cell(createdAt)="data">
        <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
      </template>

      <template #cell(payinAmount)="data">
        <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
      </template>

      <template #cell(payoutAmount)="data">
        <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
      </template>

      <template #cell(actions)="data">

        <div class="text-nowrap">
          <b-img
            :id="`account-row-${data.item.realId}-send-icon`"
            :src="require('@/assets/images/icons/actions/edit.svg')"
            height="22"
            class="cursor-pointer"
            @click="$router.push({ path: `/merchant-management/account/${data.item.hashedId}`})"
          />
          <b-tooltip
            title="Sửa"
            class="cursor-pointer"
            :target="`account-row-${data.item.realId}-send-icon`"
          />

          <b-img
            :id="`account-row-${data.item.realId}-preview-icon`"
            :src="require('@/assets/images/icons/actions/wallet.svg')"
            height="22"
            class="mx-1 cursor-pointer"
            @click="addMoneyDialog.show(data.item)"
          />
          <b-tooltip
            title="Điều chỉnh số dư"
            class="cursor-pointer"
            :target="`account-row-${data.item.realId}-preview-icon`"
          />

          <b-img
            :id="`account-row-${data.item.realId}-stop-icon`"
            :src="require('@/assets/images/icons/actions/stop.svg')"
            height="22"
            class="cursor-pointer"
            @click="handleStopMerchant(data.item)"
          />
          <b-tooltip
            title="Tạm dừng"
            class="cursor-pointer"
            :target="`account-row-${data.item.realId}-stop-icon`"
          />
        </div>
      </template>
    </b-table>

    <b-row>
      <b-col class="d-flex justify-content-md-end">
        <b-pagination
          v-model="pagination.current_page"
          :total-rows="pagination.total"
          :per-page="pagination.per_page"
          first-text="Trang đầu"
          prev-text="Trang trước"
          next-text="Trang tiếp"
          last-text="Trang cuối"
          @change="changePage"
        />
      </b-col>
    </b-row>

    <add-money-dialog
      ref="addMoneyDialog"
      @addMoneySuccess="fetchMerchant"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BTable,
  BTooltip,
  BImg,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { formatDateTimeDb } from '@core/utils/utils'
import { onMounted, ref } from '@vue/composition-api'
import AddMoneyDialog from '@/views/merchant-management/AddMoneyDialog.vue'
import Swal from 'sweetalert2'
import { useMasterFilter, useMerchantDetail, useMerchantFilter } from './useMerchant'

export default {
  name: 'Account',
  components: {
    AddMoneyDialog,
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BTable,
    BTooltip,
    BImg,
    vSelect,
    BPagination,
  },
  setup() {
    const addMoneyDialog = ref(null)
    const {
      dateFrom,
      dateTo,
      userStatus,
      userStatusOptions,
      search,
    } = useMasterFilter()

    const {
      fields,
      items,
      pagination,
      handleSearch,
      mapStatus,
    } = useMerchantFilter()

    const isCallingApi = ref(false)

    const { updateMerchant } = useMerchantDetail()

    const fetchMerchant = async () => {
      const res = await handleSearch({
        dateFrom: dateFrom.value,
        dateTo: dateTo.value,
        orderStatus: userStatus.value,
        search: search.value,
        page: pagination.value.current_page,
      })
      items.value = res.data
      pagination.value = res.meta.pagination
    }

    const handleStopMerchant = async merchant => {
      if (isCallingApi.value) return
      if (merchant.status === 'disable') {
        await Swal.fire({
          title: 'Thông báo',
          text: 'Tài khoản đã bị tạm dừng',
          icon: 'warning',
          confirmButtonText: 'Đồng ý',
        })
        return
      }
      try {
        isCallingApi.value = true
        const res = await updateMerchant({
          merchantId: merchant.hashedId,
          status: 'disable',
        })
        if (res) {
          await fetchMerchant()
          await Swal.fire({
            icon: 'success',
            title: 'Tạm dừng tài khoản thành công',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      } catch (error) {
        await Swal.fire({
          icon: 'error',
          title: 'Lỗi',
          text: 'Đã có lỗi xảy ra vui lòng thử lại sau',
        })
      }
      isCallingApi.value = false
    }

    const changePage = page => {
      pagination.value.current_page = page
      fetchMerchant()
    }

    onMounted(() => {
      fetchMerchant()
    })

    return {
      dateFrom,
      dateTo,
      userStatus,
      userStatusOptions,
      addMoneyDialog,
      isCallingApi,
      search,

      fields,
      items,
      pagination,

      fetchMerchant,
      mapStatus,
      formatDateTimeDb,
      handleStopMerchant,
      changePage,
    }
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
}

.vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
</style>
