<template>
  <b-modal
    id="modal-add-money"
    ref="modal"
    title="Điều chỉnh số dư"
    centered
    :hide-footer="true"
  >
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-form-radio-group
          v-model="itemView"
          class="ml-1 list item-view-radio-group"
          buttons
          size="sm"
          button-variant="outline-primary"
        >
          <b-form-radio
            v-for="option in itemViewOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.text }}
            <feather-icon
              :icon="option.icon"
              size="18"
            />
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>
    <validation-observer
      ref="addMoneyForm"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2 ml-3 mr-3"
        @submit.prevent="addMoney"
      >
        <b-row
          v-if="itemView === 'pay-in'"
          cols="1"
        >
          <b-col>
            <b-form-group
              label="QR bank"
              label-for="moneyQrBank"
            >
              <validation-provider
                #default="{ errors }"
                name="QR bank"
                vid="moneyQrBank"
                rules="integer"
              >
                <b-form-input
                  id="moneyQrBank"
                  v-model="moneyQrBank"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
              label="Ví USDT"
              label-for="usdt"
            >
              <validation-provider
                #default="{ errors }"
                name="Ví USDT"
                vid="usdt"
                rules="integer"
              >
                <b-form-input
                  id="usdt"
                  v-model="usdt"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row
          v-else
          cols="1"
        >
          <b-col>
            <b-form-group
              label="Payout"
              label-for="moneyPayout"
            >
              <validation-provider
                #default="{ errors }"
                name="Payout"
                vid="moneyPayout"
                rules="integer"
              >
                <b-form-input
                  id="moneyPayout"
                  v-model="moneyPayout"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <b-row class="pt-2">
        <b-col class="d-flex justify-content-center">
          <b-button
            type="submit"
            variant="primary"
            class="mr-1 mb-1"
            :disabled="invalid"
            @click="addMoney(currentMerchant)"
          >
            Điều chỉnh số dư
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BForm,
  BButton,
  BFormRadio,
  BFormRadioGroup,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer } from '@validations'
import { useDialogAddMoney } from '@/views/merchant-management/useMerchant'
import Swal from 'sweetalert2'
import musicIcon from 'vue-feather-icons/icons/MusicIcon'

export default {
  name: 'AddMoneyDialog',
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BFormRadio,
    BFormRadioGroup,
  },
  setup(props, { emit }) {
    const modal = ref(null)
    const currentMerchant = ref(null)
    const itemView = ref('pay-in')
    const itemViewOptions = ref([
      { icon: 'EyeIcon', value: 'pay-in', text: 'Pay In' },
      { icon: 'EyeOffIcon', value: 'pay-out', text: 'Pay Out' },
    ])

    const moneyQrBank = ref(null)
    const usdt = ref(null)
    const moneyPayout = ref(null)

    const {
      handleAddMoney,
    } = useDialogAddMoney()

    const show = merchant => {
      currentMerchant.value = merchant
      modal.value.show()
    }

    const addMoney = merchant => {
      const params = {
        id: merchant.hashedId,
      }
      if (moneyQrBank.value) {
        params.qr_bank = moneyQrBank.value
      }

      if (usdt.value) {
        params.usdt = usdt.value
      }

      if (moneyPayout.value) {
        params.payout = moneyPayout.value
      }

      handleAddMoney(params)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Điều chỉnh số dư thành công',
            showConfirmButton: false,
            timer: 1500,
          })
          emit('addMoneySuccess')
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: 'Điều chỉnh số dư thất bại',
            showConfirmButton: false,
            timer: 1500,
          })
        })
        .finally(() => {
          modal.value.hide()
        })
    }

    return {
      modal,
      itemView,
      itemViewOptions,
      moneyQrBank,
      usdt,
      moneyPayout,
      currentMerchant,

      required,
      integer,

      addMoney,
      show,
    }
  },
}
</script>

<style scoped>

</style>
