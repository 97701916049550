import { ref } from '@vue/composition-api'
import store from '@/store'

export const useMasterFilter = () => {
  const dateFrom = ref('')
  const dateTo = ref('')
  const userStatus = ref('')
  const userStatusOptions = ref([
    { value: 'disable', text: 'Vô hiệu hoá' },
    { value: 'active', text: 'Đang hoạt động' },
    { value: 'pending', text: 'Chờ phê duyệt' },
  ])
  const search = ref('')

  return {
    search,
    dateFrom,
    dateTo,
    userStatus,
    userStatusOptions,
  }
}

export const useMerchantFilter = () => {
  // table
  const pageLength = ref(10)
  const fields = ref([
    '#',
    { key: 'merchantCode', label: 'ID' },
    { key: 'name', label: 'khách hàng' },
    { key: 'parent', label: 'thuộc đại lý' },
    { key: 'payinAmount', label: 'số dư payin' },
    { key: 'payoutAmount', label: 'số dư payout' },
    { key: 'payinUsdtAmount', label: 'số dư usdt' },
    { key: 'createdAt', label: 'thời gian tạo' },
    { key: 'status', label: 'trạng thái' },
    { key: 'actions', label: 'Hành động' },
  ])
  const items = ref([])

  const pagination = ref({
    total: 1, count: 1, per_page: 15, current_page: 1, total_pages: 1,
  })

  const handleSearch = (...args) => store.dispatch('merchant-management/fetchMerchant', ...args)

  const mapStatus = status => {
    switch (status) {
      case 'active':
        return 'Đang hoạt động'
      case 'disable':
        return 'Vô hiệu hoá'
      case 'pending':
        return 'Chờ phê duyệt'
      default:
        return 'Chờ phê duyệt'
    }
  }

  const handlePageChange = () => {
    console.log('page change')
  }

  return {
    pageLength,
    fields,
    items,
    pagination,

    handleSearch,
    handlePageChange,
    mapStatus,
  }
}

export const useMerchantDetail = () => {
  const merchant = ref({})
  const payInConfig = ref({})
  const payOutConfig = ref({})
  const viettelConfig = ref({})
  const vinaConfig = ref({})
  const mobiConfig = ref({})
  const vietnamobileConfig = ref({})
  const momoQrConfig = ref({})
  const zaloQrConfig = ref({})
  const viettelQrConfig = ref({})
  const usdtConfig = ref({})

  const getConfigByName = (name, configs) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < configs.length; i++) {
      if (configs[i].pay_gate.gate === name) {
        return {
          id: configs[i].id,
          isActive: configs[i].is_active,
          percent_fee: configs[i].percent_fee,
        }
      }
    }
    return {}
  }

  const fetchMerchantDetail = (...args) => store.dispatch('merchant-management/fetchMerchantDetail', ...args)
  const updateMerchant = (...args) => store.dispatch('merchant-management/updateMerchant', ...args)

  return {
    merchant,
    payInConfig,
    payOutConfig,
    viettelConfig,
    vinaConfig,
    mobiConfig,
    vietnamobileConfig,
    momoQrConfig,
    zaloQrConfig,
    viettelQrConfig,
    usdtConfig,
    fetchMerchantDetail,
    getConfigByName,
    updateMerchant,
  }
}

export const useDialogAddMoney = () => {
  const handleAddMoney = (...args) => store.dispatch('merchant-management/addMoneyManual', ...args)

  return {
    handleAddMoney,
  }
}
